import Image from 'next/image';
import LanguageLocalizer from '@/utils/language-localization';
import Link from 'next/link';
import MediaBadgeNew from '@/components/base/MediaBadge/MediaBadgeNew';
import MediaBadgeText from '@/components/base/MediaBadge/MediaBadgeText';
import musicBadge from '@/public/puma/images/MusicBadge.svg';
import React, { ReactElement, ReactNode, useContext } from 'react';
import star from '@/public/puma/images/star.svg';
import StyledIcon from '@/components/base/StyledIcon';
import { Maybe, PbsKidsVideo } from '@/types/pbskids-graph';
import styles from './MediaItem.module.scss';
import { MediaContext } from '@/types/mediaContext';
import { getIsNew, getVideoTitle } from '@/managers/VideoPlayer/dataTransform';
import { singleTransparentPixel } from '@/utils/theming-system';

const secondsToMinutes = (seconds: number) => Math.round(seconds / 60);

const formattedDuration = (duration?: Maybe<number>): ReactNode => {
  if (!duration) {
    return <></>;
  }

  if (duration < 60) {
    return <span className={styles.videoLength}>
      {duration}<span aria-hidden='true'>s</span><span className='sr-only'>seconds.</span>
    </span>;
  }

  return <span className={styles.videoLength}>
    { secondsToMinutes(duration) }<span aria-hidden='true'>m</span><span className='sr-only'>minutes.</span>
  </span>;
};

export enum CardStyle {
  smallSquare = 'smallSquare',
  largeSquare = 'largeSquare',
  poster = 'poster',
  mezzanine = 'mezzanine',
  singleVideo = 'singleVideo',
}

export default function VideoCard({ videoItem, cardStyle, context, inMasthead = false, pageId, sizes }: {
  videoItem: PbsKidsVideo,
  cardStyle?: string,
  context?: MediaContext,
  inMasthead?: boolean,
  pageId?: string,
  sizes?: string,
}): ReactElement {
  if (!videoItem.mediaManagerAsset || !videoItem.mediaManagerAsset.images) {
    return <></>;
  }

  const mediaManagerImage = videoItem.mediaManagerAsset.images.find((image) => image?.profile === 'asset-kids-mezzanine1-16x9');

  const cardImage = (cardStyle: string | undefined) => {
    switch (cardStyle) {
      case CardStyle.smallSquare:
      case CardStyle.largeSquare:
        return videoItem?.squareImage?.[0]?.url;

      case CardStyle.poster:
        return videoItem?.posterImage?.[0]?.url;

      case CardStyle.mezzanine:
        return videoItem?.mezzanine?.[0]?.url || mediaManagerImage?.image;

      case CardStyle.singleVideo:
      default:
        return mediaManagerImage?.image;
    }
  };

  const { __t } = new LanguageLocalizer( useContext, 'components/modules/MediaList' );

  const videoTitle = getVideoTitle(videoItem);
  const link = context?.id ?
    `/videos/watch/${context.slug}/${context.id}/${videoItem.slug}/${videoItem.id}` :
    `/videos/watch/${videoItem.slug}/${videoItem.id}`;

  // Don't display the showTitle if `displayShowTitle` is explicitly set to false.
  const propertyTitle = context?.displayShowTitle === false ? undefined :
    (videoItem.properties && videoItem.properties[0] ? videoItem.properties[0].title || '' : '');

  const alt = videoItem.mediaManagerAsset.description_short || '';

  const badges = {
    'newContent': getIsNew(videoItem),
    'fullEpisode': videoItem.videoType === 'fullEpisode',
    'movie': videoItem.videoType === 'movie',
    'music': videoItem.videoType === 'music',
  };

  const showNewBadge = (): ReactNode => {
    if (badges.newContent) {
      return (
        <div className={styles.newBadgeWrapper}>
          <MediaBadgeNew inMasthead={ inMasthead} />
        </div>
      );
    }
  };

  const contentBadgeType = (): ReactNode => {
    if (badges.fullEpisode) {
      return (
        <MediaBadgeText
          text={__t('fullEpisodeBadgeLabel')}
          icon={ star }
        />
      );
    } else if (badges.movie) {
      return (
        <MediaBadgeText
          text={__t('movieBadgeLabel')}
          bgColor={styles.movieBadgeLabel}
        />
      );
    }
  };

  const showContentBadge = (): ReactNode => {
    if (badges.fullEpisode || badges.movie) {
      return (
        <div className={ styles.mediaBadgeWrapper }>
          { contentBadgeType() }
        </div>
      );
    } else if (badges.music) {
      return (
        <>
          <span className='sr-only'>{__t('musicBadgeLabel')}</span>
          <StyledIcon
            aria-hidden='true'
            icon={ musicBadge }
            classList={ styles.musicBadgeIcon }
          />
        </>
      );
    }
  };

  const isLinkCard = cardStyle && [
    CardStyle.smallSquare,
    CardStyle.largeSquare,
    CardStyle.poster,
  ].includes(cardStyle as CardStyle);

  return (
    <>
      { isLinkCard && <>
        <article className={styles.linkCard}>
          <div data-use-loading-dots>
            <Link
              href={ link }
            >
              <Image
                src={cardImage(cardStyle) || singleTransparentPixel}
                alt={ alt }
                width={300}
                height={300}
                sizes={ sizes || '300px' }
              />
            </Link>
          </div>
        </article>
      </>}

      { !isLinkCard && <>
        <article
          data-hover-group='media-card'
          className={ `${styles.videoCard} ${inMasthead ? styles.inMasthead : ''}` }
        >
          <div className={ `${styles.imageContainer} aspect-video` } data-use-loading-dots>
            <Image
              src={ cardImage(cardStyle) || singleTransparentPixel }
              alt={ alt }
              fill
              className={ `${styles.mediaImage} ${link ? styles.mediaItemLink : ''}` }
              sizes={ sizes || '100vw' }
            ></Image>

            { formattedDuration(videoItem.mediaManagerAsset.duration) }
            { showContentBadge() }
          </div>

          { showNewBadge() }

          <Link
            href={ pageId ? `${link}?videopage=${pageId}` : link }
            className={styles.mediaLink}
          >
            <div className={ `${styles.mediaCardDetailsContainer} ${!propertyTitle ? styles.doubleStacked : ''} mediaCardDetails` }>
              {propertyTitle && <p className={ styles.heading }>
                { propertyTitle }
              </p>}

              <p>{ videoTitle }</p>
            </div>
          </Link>
        </article>
      </> }
    </>
  );
}

