import React from 'react';
import { ReactNode } from 'react';
import styles from './PageSectionStack.module.scss';

interface Props {
  children: ReactNode,
  className?: string,
}

const PageSectionStack: React.FC<Props> = ({
  children, className,
}) => {
  return (
    <div
      className={ `${styles.innerWrapper} ${className || ''}`}
    >
      { children }
    </div>
  );
};

export default PageSectionStack;
