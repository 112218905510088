import BridgeLink from '@/components/base/BridgeLink';
import { Maybe, Sponsor } from '@/types/pbskids-graph';
import LanguageLocalizer from '@/utils/language-localization';
import styles from './SponsorsGrid.module.scss';
import Image from 'next/image';
import { useContext } from 'react';

type SponsorGridProps = {
  sponsors: Sponsor[];
  logoStyle?: Maybe<string>;
};

export default function SponsorsGrid({ sponsors, logoStyle }: SponsorGridProps) {
  const sponsorImageType = (logoStyle === 'dark') ? 'sponsorDarkImage' : 'sponsorLightImage';
  const { __t } = new LanguageLocalizer( useContext, 'components/modules/SponsorsGrid' );

  return (
    <aside
      aria-labelledby='sponsors-grid-title'
      className={`contain ${styles.sponsors}`}>
      <div
        id='sponsors-grid-title'
        className={styles.sponsorsTitle}>
        {__t('fundedBy')}
      </div>

      <ul
        aria-labelledby='sponsors-grid-title'
        className={styles.sponsorsList}>
        {sponsors.map((sponsor, index) => (
          sponsor[sponsorImageType]?.[0] &&
          <li key={index}>
            <BridgeLink
              href={sponsor.sponsorUrl || ''}
              imageAltText={sponsor.imageAltText || ''}
              modalBody={sponsor.bridgeBodyText || ''}
              modalHeader={sponsor.bridgeHeadline || ''}
              modalImage={sponsor.bridgeImage?.[0]?.url || ''}
              title={sponsor.title || ''}
            >
              <Image
                width={120}
                height={60}
                src={sponsor[sponsorImageType]?.[0]?.url || ''}
                sizes='(min-width: 768px) 120px, (min-width: 568px) 90px, 68px '
                alt={sponsor.imageAltText || sponsor.title || ''}
              />
            </BridgeLink>
          </li>
        ))
        }
      </ul>
    </aside>
  );
}
